<template>
  <router-view/>
</template>

<script>
import { EventBus } from "@/main.js";

export default {
  name: 'App',
  created() {
    EventBus.$on("errorNotify", (data) => {
      this.$bvToast.toast(data, {
        title: "Atenção",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    });
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import "./assets/scss/main.scss";

.nao-encontrado {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  border-radius: 5px;
  padding: 20px !important;
  background: #f7f7f7;
  margin: 20px auto;
}

// notificações
.toast {
  max-width: 400px !important;
  font-size: 14px !important;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: initial !important;
  border: 1px solid;
  box-shadow: none !important;
  border-radius: 5px !important;
  backdrop-filter: blur(0) !important;
  padding: 20px 30px;
  padding-left: 50px;
  font-family: Montserrat, sans-serif;
  outline: none !important;
  animation: showRight 0.3s forwards !important;
}
.b-toast,
.b-toaster-slot {
  max-width: 400px !important;
}
.b-toaster,
.swal2-container {
  z-index: 9999999 !important;
}
.toast-header,
.toast-body {
  padding: 0 !important;
  position: relative;
  line-height: 1.5 !important;
  font-size: 14px !important;
}
.toast-body {
  color: #5f5c6b !important;
}
.toast .close {
  font-weight: 500 !important;
  line-height: 1;
  color: #5f5c6b;
  text-shadow: none !important;
  opacity: 0.5;
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
  outline: none !important;
}

.toast-header strong {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600 !important;
}
.toast-header strong::before {
  content: "";
  background: url("./assets/icons/notify_default.svg") no-repeat center
    center;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: -30px;
}
// sucesso
.b-toast-success .toast-header strong {
  color: #00e4a0;
}
.b-toast-success .toast-header strong::before {
  background: url("./assets/icons/notify_success.svg") no-repeat center;
}
.b-toast-success .toast {
  background-color: #f2fefa !important;
  border-color: #00e4a0 !important;
  color: #00e4a0 !important;
}
.b-toast-success .toast .toast-header {
  color: #00e4a0 !important;
  background-color: #f2fefa !important;
  border: none !important;
}
.b-toast-success.b-toast-solid .toast {
  background-color: #f2fefa !important;
}

// info
.b-toast-info .toast-header strong {
  color: #2133d2;
}
.b-toast-info .toast-header strong::before {
  background: url("./assets/icons/notify_info.svg") no-repeat center;
}
.b-toast-info .toast {
  background-color: #f4f5fd !important;
  border-color: #2133d2 !important;
  color: #2133d2 !important;
}
.b-toast-info .toast .toast-header {
  color: #2133d2 !important;
  background-color: #f4f5fd !important;
  border: none !important;
}
.b-toast-info.b-toast-solid .toast {
  background-color: #f4f5fd !important;
}

// danger
.b-toast-danger .toast-header strong {
  color: #ff0c37;
}
.b-toast-danger .toast-header strong::before {
  background: url("./assets/icons/notify_danger.svg") no-repeat center;
}
.b-toast-danger .toast {
  background-color: #fff3f5 !important;
  border-color: #ff0c37 !important;
  color: #ff0c37 !important;
}
.b-toast-danger .toast .toast-header {
  color: #ff0c37 !important;
  background-color: #fff3f5 !important;
  border: none !important;
}
.b-toast-danger.b-toast-solid .toast {
  background-color: #fff3f5 !important;
}

// warning
.b-toast-warning .toast-header strong {
  color: #ffcb37;
}
.b-toast-warning .toast-header strong::before {
  background: url("./assets/icons/notify_warning.svg") no-repeat center;
}
.b-toast-warning .toast {
  background-color: #fffcf5 !important;
  border-color: #ffcb37 !important;
  color: #ffcb37 !important;
}
.b-toast-warning .toast .toast-header {
  color: #ffcb37 !important;
  background-color: #fffcf5 !important;
  border: none !important;
}
.b-toast-warning.b-toast-solid .toast {
  background-color: #fffcf5 !important;
}

// default
.toast-header strong {
  color: #333;
}
.toast-header strong::before {
  background: url("./assets/icons/notify_default.svg") no-repeat center;
}
.toast {
  background-color: #f6f6f7 !important;
  border-color: #333 !important;
  color: #333 !important;
}
.toast .toast-header {
  color: #333 !important;
  background-color: #f6f6f7 !important;
  border: none !important;
}
.b-toast-solid .toast {
  background-color: #f6f6f7 !important;
}

</style>
