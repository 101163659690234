const state = {
  showMaxSidebar: true,
};

const getters = {
  sidebarState: currentState => currentState.showMaxSidebar,
};

const mutations = {
  changeSidebarState(currentState) {
    currentState.showMaxSidebar = !currentState.showMaxSidebar;
  },
};

const actions = {
  showError(erro) {
    console.log(erro)
  }
};

export {
  state,
  getters,
  mutations,
  actions,
};