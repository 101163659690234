import middleware from '@/router/middleware'
import authentication from '@/router/middleware/authentication'
import unauthentication from '@/router/middleware/unauthentication'


export default [
  {
    path: '/',
    name: 'Rastreamento',
    component: () =>
      import('../../views/Rastreamento.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import('../../views/Login.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () =>
      import('../../views/Dashboard.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/pedidos',
    name: 'PedidosOFF',
    component: () =>
      import('../../views/Dashboard.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/pedidos/:id',
    name: 'Pedidos',
    component: () =>
      import('../../views/Pedidos.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/integracoes',
    name: 'Integrações',
    component: () =>
      import('../../views/Integracoes.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/integracoes/melhor-envio',
    name: 'Integrações',
    component: () =>
      import('../../views/MelhorEnvio.vue'),
  },
  {
    path: '/frete-gratis',
    name: 'Frete grátis',
    component: () =>
      import('../../views/FreeShipping.vue'),
    beforeEnter: middleware([authentication]),
  },
]