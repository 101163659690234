import Cookies from 'js-cookie';


export default {

    authSuccess: (state, access_tokenn) => {
        state.access_tokenn = access_tokenn;
    },
    dataMember: (state, dados) => {
        state.dados = dados;
    },
    authError: () => {
        /* let errors=err.errors?err.errors:{};
         if(err.error=="invalid_credentials"){
             errors.invalid_credentials=['The user credentials were incorrect.'];
         }

         state.status = 'error';
         state.hasLoadedOnce = true;
         state.errors.record(errors);*/
    },
    authauthSuccesst: (state) => {
        state.access_tokenn = '';
        Cookies.remove('access_tokenn');
    }
}