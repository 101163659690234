import Cookies from 'js-cookie';

export default {

    userSuccess: (state, resp) => {
        state.user = resp;
        localStorage.user = JSON.stringify(resp)
        Cookies.set('user', JSON.stringify(resp), {
            expires: 365
        });
    },
    userClub: (state, resp) => {
        state.userClub = resp;
        Cookies.set('club_integration', JSON.stringify(resp), {
            expires: 365
        });
    },
    verifyEmail: (state, resp) => {
        state.verifyEmail = resp;
        Cookies.set('verify_email', JSON.stringify(resp), {
            expires: 365
        });
    },
    adminUserSuccess: (state, resp) => {
        state.user = resp;
    },
    userLogout: (state) => {
        state.user = null;
        state.userClub = null;
        state.verifyEmail = null
        state.access_tokenn = '';
        Cookies.remove('access_tokenn');
        Cookies.remove('club_integration');
        Cookies.remove('user');
        Cookies.remove('verify_email');
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = window.location.protocol + "//" + window.location.host
    }

}