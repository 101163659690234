import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueTheMask from 'vue-the-mask';
import BaseButton from '@/components/base/Button.vue'
import VeeValidate from 'vee-validate';
import {
  Validator
} from 'vee-validate';
import {
  date,
  datetime
} from './filters/date';

import pt_BR from 'vee-validate/dist/locale/pt_BR';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueMask from 'v-mask'
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueMask);
Vue.component('BaseButton', BaseButton)
Vue.use(VeeValidate)
Vue.filter('date', date);
Vue.filter('datetime', datetime);
Validator.localize('pt_BR', pt_BR);
Vue.use(VueTheMask);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false

export const EventBus = new Vue();

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')