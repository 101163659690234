import Cookies from 'js-cookie';


import actions from '@/store/modules/auth/actions'
import getters from '@/store/modules/auth/getters';
import mutations from '@/store/modules/auth/mutations'



const state = {
	access_tokenn: Cookies.get('access_tokenn') || '',
	dados: Cookies.get('access_member') || '',
}





export {
  state,
  getters,
  mutations,
  actions
}