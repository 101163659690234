import Cookies from 'js-cookie';
import AuthService from '@/services/resources/AuthService';
// import router from '@/router/index'

export default {

    registerLogin(context, payload){
        let access_tokenn = payload;
        Cookies.set('access_tokenn', access_tokenn, {
            expires: 365
        });
        context.commit('authSuccess', access_tokenn);
    },

    saveMember(context, payload){
        let access_member = payload;
        Cookies.set("access_member", payload, {
            expires: 365,
          });
        context.commit('dataMember', access_member);
    },

    loginRequest(context, payload) {
        let service = AuthService.build();
        return new Promise((resolve, reject) => {

            service.login(payload.username, payload.password)
                .then((resp) => {
                    let access_tokenn = resp.auth;
                    Cookies.set('access_tokenn', access_tokenn, {
                        expires: 365
                    });
                    context.commit('authSuccess', access_tokenn);
                    // context.dispatch('userRequest');
                    resolve(access_tokenn);
                })
                .catch((err) => {
                    context.commit('authError', err);
                    Cookies.remove('access_tokenn');
                    reject(err);
                })
        })

    },

    logoutRequest: ({
        commit
    }) => {
        commit('authLogout');
        commit('userLogout');
        window.$crisp.push(['do', 'chat:hide']);
    }
}